import '../App.css';
import {
    App, BlockHeader, BlockTitle, f7ready, f7, Input, List, ListInput, ListItem, Page
} from "framework7-react";
import {useEffect, useState} from "react";
import logo from "../assets/gender.svg";
import map from "../assets/map.svg";
import {useTranslation} from "react-i18next";

function About() {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const { i18n, t } = useTranslation();
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [gender, setGender] = useState("");
    const [region, setRegion] = useState("");

    const [nameIsEmpty, setNameIsEmpty] = useState(false);
    const [lastNameIsEmpty, setLastNameIsEmpty] = useState(false);
    const [birthdayIsEmpty, setBirthdayIsEmpty] = useState(false);
    const [genderIsEmpty, setGenderIsEmpty] = useState(false);
    const [regionIsEmpty, setRegionIsEmpty] = useState(false);

    useEffect(() => {
        const handleBackButton = () => {
            f7.views.main.router.back();
            window.Telegram.WebApp.BackButton.isVisible = false;
        };

        const handleMainButton = () => {
            let canNavigate = true;

            if (name === "" || name.trim().length === 0) {
                setNameIsEmpty(true);
                canNavigate = false;
            } else {
                setNameIsEmpty(false);
            }

            if (lastName === "" || lastName.trim().length === 0) {
                setLastNameIsEmpty(true);
                canNavigate = false;
            } else {
                setLastNameIsEmpty(false);
            }
            if (gender === "" || gender.trim().length === 0) {
                setGenderIsEmpty(true);
                canNavigate = false;
            } else {
                setGenderIsEmpty(false);
            }
            if (region === "" || region.trim().length === 0) {
                setRegionIsEmpty(true);
                canNavigate = false;
            } else {
                setRegionIsEmpty(false);
            }

            if (birthday === "") {
                setBirthdayIsEmpty(true);
                canNavigate = false;
            } else {
                setBirthdayIsEmpty(false);
            }

            if (canNavigate) {
                localStorage.setItem('username', username);
                localStorage.setItem('name', name);
                localStorage.setItem('lastName', lastName);
                localStorage.setItem('gender', gender);
                localStorage.setItem('region', region);
                localStorage.setItem('birthday', birthday);
                f7.views.main.router.navigate('/role');
                window.Telegram.WebApp.offEvent('mainButtonClicked', handleMainButton)
            }
        };

        const user = window.Telegram.WebApp.initDataUnsafe.user;

        if (user) {
            let telegramId = user.id;
            localStorage.setItem('telegramId', telegramId);
            setUsername(user.username);
        }

        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t("mainButton");
        window.Telegram.WebApp.onEvent("backButtonClicked", handleBackButton);
        window.Telegram.WebApp.onEvent("mainButtonClicked", handleMainButton);
        window.Telegram.WebApp.onEvent('mainButtonClicked',() => {
            const data = {
                TelegramId: localStorage.getItem('telegramId'),
                language: localStorage.getItem("language"),
                username: localStorage.getItem('username'),
                name: localStorage.getItem('name'),
                lastName: localStorage.getItem('lastName'),
                birthday: localStorage.getItem('birthday'),
                gender: localStorage.getItem('gender'),
                region: localStorage.getItem('region'),

            };
        });

        return () => {
            window.Telegram.WebApp.offEvent("backButtonClicked", handleBackButton);
            window.Telegram.WebApp.offEvent("mainButtonClicked", handleMainButton);

        };
    }, [username, name, lastName, birthday, gender, region, t]);

    return (
        <Page>
            <BlockTitle style={{
                fontSize: 25,
                overflow: 'visible',
                width: '300px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                marginBottom: '10px',
                lineHeight: 1.2
            }}>{t("title")}</BlockTitle>
            <BlockHeader>{t("enterName")}</BlockHeader>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="text"
                    placeholder={t("firstName")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    errorMessage={t("firstName")}
                    errorMessageForce={nameIsEmpty}
                />
            </List>
            <List style={{marginTop:-20}} strongIos dividersIos insetIos>
                <ListInput
                    required
                    type="text"
                    placeholder={t("lastName")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    errorMessage={t("lastName")}
                    errorMessageForce={lastNameIsEmpty}
                />
            </List>
            <BlockHeader>{t("chooseBirthday")}</BlockHeader>
            <List strongIos dividersIos insetIos>
                <ListInput
                    type="datepicker"
                    placeholder={t("birthday")}
                    readonly
                    errorMessage={t("birthday")}
                    errorMessageForce={birthdayIsEmpty}
                    calendarParams={{
                        openIn: 'customModal',
                        header: true,
                        footer: true,
                        yearPickerMin: 1950,
                        yearPickerMax: 2010,
                        locale: i18n.language,
                        toolbarCloseText: t("done"),
                        touchMove: false,
                        on: {
                            open(cal) {
                                cal.setYearMonth(1990, 1, 0);
                                cal.update();
                            },
                            change(calendar, value) {
                                setBirthday(value[0]);
                            }
                        }
                    }}
                />
            </List>

            <List strongIos dividersIos insetIos>
                <ListItem smartSelectParams={{ openIn: 'popover', closeOnSelect: true }} title={t("gender")} smartSelect>
                    <img
                        slot="media"
                        style={{ borderRadius: '4px' }}
                        src={logo}
                        width="20"
                    />
                    <select name={gender} onChange={(e) => setGender(e.target.value)}>
                        <option>{t("chooseGender")}</option>
                        <option value="Мужской">{t("male")}</option>
                        <option value="Женский">{t("female")}</option>
                    </select>
                </ListItem>
                <ListItem smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                          title={t("region")}
                          smartSelect
                >
                    <img
                        slot="media"
                        style={{ borderRadius: '4px' }}
                        src={map}
                        width="20"
                    />
                    <select onChange={(e) => setRegion(e.target.value)} name={region}>
                        <option>{t("chooseRegion")}</option>
                        <option value="Ташкент">{t('regions.tashkent')}</option>
                        <option value="Ташкентская обл">{t('regions.tashkent_obl')}</option>
                        <option value="Фергана">{t('regions.fergana')}</option>
                        <option value="Андижан">{t('regions.andijan')}</option>
                        <option value="Наманган">{t('regions.namangan')}</option>
                        <option value="Бухара">{t('regions.bukhara')}</option>
                        <option value="Навои">{t('regions.navoi')}</option>
                        <option value="Хорезм">{t('regions.khorezm')}</option>
                        <option value="Сурхандарья">{t('regions.surkhandarya')}</option>
                        <option value="Кашкадарья">{t('regions.kashkadarya')}</option>
                        <option value="Сырдарья">{t('regions.syrdarya')}</option>
                        <option value="Джиззак">{t('regions.jizzakh')}</option>
                        <option value="Самарканд">{t('regions.samarkand')}</option>
                        <option value="Каракалпакстан">{t('regions.karakalpakstan')}</option>
                    </select>
                </ListItem>
            </List>
        </Page>
    );
}

export default About;
