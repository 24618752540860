import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next)
    .init({
        fallbackLng: "uz",
        lng: "uz",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            ru: {
                translation: {
                    welcome: 'Добро пожаловать в',
                    mainButton: "ПРОДОЛЖИТЬ",
                    title: "Введите данные о себе",
                    enterName: "Введите имя и фамилию",
                    language: "Выберите язык",
                    username: "Никнейм",
                    firstName: "Ваше Имя",
                    lastName: "Ваша Фамилия",
                    gender: "Ваш пол",
                    region: "Регион",
                    chooseGender: "Пол",
                    male: "Мужской",
                    female: "Женский",
                    chooseRegion: "Выберите регион",
                    birthday: "Дата рождения",
                    chooseBirthday: "Укажите дату рождения",
                    monthsArray: [
                        "Январь",
                        "Февраль",
                        "Март",
                        "Апрель",
                        "Май",
                        "Июнь",
                        "Июль",
                        "Август",
                        "Сентябрь",
                        "Октябрь",
                        "Ноябрь",
                        "Декабрь"
                    ],
                    regions: {
                        tashkent: "г. Ташкент",
                        tashkent_obl: "Ташкентская область",
                        fergana: "Ферганская область",
                        samarkand: "Самаркандская область",
                        bukhara: "Бухарская область",
                        andijan: "Андижанская область",
                        namangan: "Наманганская область",
                        navoi: "Навоийская область",
                        khorezm: "Хорезмская область",
                        surkhandarya: "Сурхандарьинская область",
                        kashkadarya: "Кашкадарьинская область",
                        syrdarya: "Сырдарьинская область",
                        jizzakh: "Джизакская область",
                        karakalpakstan: "Республика Каракалпакстан",
                    },
                    choseSphere: "Выберите направление",
                    choseSpeciality: "Выберите специальность",
                    choseLevel: "Выберите уровень",
                    specialityTitle: "Укажите свою специальность",
                    sphere: "Направление",
                    speciality: "Специальность",
                    level: "Уровень",
                    experience: "Опыт работы",
                    months: "месяцев",
                    Month: "Месяц",
                    Year: "Год",
                    Day: "День",
                    year: "год",
                    years: "лет",
                    ITroles: [
                        "DevOps",
                        "QA Engineer",
                        "Front-end Developer",
                        "Back-end Developer",
                        "Fullstack Developer",
                        "Mobile Developer (iOS/Android)",
                        "Game Developer",
                        "Cybersecurity Engineer",
                        "Pentester",
                        "Databases Engineer",
                        "System Analyst",
                        "Data Scientist",
                        "ML Engineer",
                        "AI Engineer (Prompt Engineer)",
                        "Deep Learning Engineer",
                        "Deep Learning Researcher",
                        "Other",
                    ],
                    digitalRoles: [
                        "Marketing",
                        "Targeting",
                        "Advertising",
                        "Analytics",
                        "Design",
                        "Photo/Video Production",
                        "Management",
                        "Sales",
                        "Other"
                    ],
                    experiences: [
                        "Student",
                        "Intern",
                        "0-6 месяцев",
                        "6-12 месяцев",
                        "1-3 года",
                        "> 3 лет"
                    ],
                    customSpec: "Ваша Специальность",
                    enterLevel: "Укажите опыт работы",
                    enterCustomSpec: "Введите вашу специальность",
                    done: "Готово!",
                    doneText: "Теперь вы можете вступить в наш частный канал нажав на кнопку ниже",
                    join: "ВСТУПИТЬ",
                    redirect_to_group: "Перенаправляем в группу...",


                },
            },
            uz: {
                translation: {

                    mainButton: "DAVOM ETISH",
                    title: "O'zingiz haqingizdagi malumotlarni kiriting",
                    language: "Tilni tanlang",
                    username: "Nikneym",
                    firstName: "Ismingiz",
                    enterName: "Ism va familyingizni kiriting",
                    lastName: "Familiyangiz",
                    gender: "Jinsingiz",
                    region: "Regioningiz",
                    chooseGender: "Jinsingizni tanlang",
                    male: "Erkak",
                    female: "Ayol",
                    chooseRegion: "Regioningizni tanlang",
                    birthday: "Tugilganingiz sanasi",
                    chooseBirthday: "Tugilgan kuningizni tanlang",
                    regions: {
                        tashkent: "Toshkent shahri",
                        tashkent_obl: "Toshkent viloyati",
                        fergana: "Farg‘ona viloyati",
                        samarkand: "Samarqand viloyati",
                        bukhara: "Buxoro viloyati",
                        andijan: "Andijon viloyati",
                        namangan: "Namangan viloyati",
                        navoi: "Navoiy viloyati",
                        khorezm: "Xorazm viloyati",
                        surkhandarya: "Surxondaryo viloyati",
                        kashkadarya: "Qashqadaryo viloyati",
                        syrdarya: "Sirdaryo viloyati",
                        jizzakh: "Jizzax viloyati",
                        karakalpakstan: "Qoraqalpog‘iston Respublikasi",
                    },
                    choseSphere: "Yo'nalishni tanlang",
                    choseSpeciality: "Mutaxassisligingizni tanlang",
                    choseLevel: "Darajangizni tanlang",
                    specialityTitle: "Mutaxassisligingizni kiriting",
                    sphere: "Yonalish",
                    speciality: "Mutaxassislingiz",
                    level: "Daraja",
                    experience: "Ish tajribasi",
                    months: "oy",
                    Year: "Yil",
                    Month: "Oy",
                    Day: "Kun",
                    year: "yil",
                    years: "yil",
                    monthsArray: [
                        "Yanvar",
                        "Fevral",
                        "Mart",
                        "Aprel",
                        "May",
                        "Iyun",
                        "Iyul",
                        "Avgust",
                        "Sentabr",
                        "Oktabr",
                        "Noyabr",
                        "Dekabr"
                    ],
                    ITroles: [
                        "DevOps",
                        "QA Engineer",
                        "Front-end Developer",
                        "Back-end Developer",
                        "Fullstack Developer",
                        "Mobile Developer (iOS/Android)",
                        "Game Developer",
                        "Cybersecurity Engineer",
                        "Pentester",
                        "Databases Engineer",
                        "System Analyst",
                        "Data Scientist",
                        "ML Engineer",
                        "AI Engineer (Prompt Engineer)",
                        "Deep Learning Engineer",
                        "Deep Learning Researcher",
                        "Other",
                    ],
                    digitalRoles: [
                        "Marketing",
                        "Targeting",
                        "Advertising",
                        "Analytics",
                        "Design",
                        "Photo/Video Production",
                        "Management",
                        "Sales",
                        "Other"
                    ],
                    experiences: [
                        "Student",
                        "Intern",
                        "0-6 oy",
                        "6-12 oy",
                        "1-3 yil",
                        "> 3 yil"
                    ],
                    enterLevel: "Ish tajribangizni kiriting",
                    customSpec: "Sizning Yonalishingiz",
                    enterCustomSpec: "Ozingizni yonalishingizni kiriting",
                    done: "Tayyor!",
                    doneText: "Endi quyidagi tugmani bosish orqali shaxsiy kanalimizga qo'shilishingiz mumkin",
                    join: "QO'SHILISH",
                    redirect_to_group: "Guruhga yo'naltirilmoqda...",
                },
            },
        },
    });

export default i18next;