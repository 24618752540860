import React, { useState } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './boomstick.json';

const BoomStickC = () => {

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div>
            <Lottie
                options={defaultOptions}
                height={100}
                width={100}
            />
        </div>
    );
};

export default BoomStickC;