import '../App.css';
import {
    App, BlockHeader, f7, f7ready, List, ListItem, Page
} from "framework7-react";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

function Start() {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const { i18n, t } = useTranslation();
    const [language, setLanguage] = useState("uz");
    const [languageIsEmpty, setLanguageIsEmpty] = useState(false);
    localStorage.setItem('language', language);


    const onChangeLang = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
    };
    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
            i18n.changeLanguage(savedLanguage);
        }
        const user = window.Telegram.WebApp.initDataUnsafe.user;
        if (user && user.id) {
            fetchUser(user.id);
        }
        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.BackButton.isVisible = false;
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t("mainButton")
        window.Telegram.WebApp.onEvent('mainButtonClicked', handleMain);

        return () => {
            window.Telegram.WebApp.offEvent("mainButtonClicked", handleMain);
        };

        window.Telegram.WebApp.BackButton.hide();
    } ,[language])

    const handleMain = () => {
        const shouldAsk = localStorage.getItem("shouldAsk");
        if (shouldAsk === "false") {
            f7.views.main.router.navigate('/about');
            return;
        }

        window.Telegram.WebApp.requestContact((isAccepted, clb) => {
            if (isAccepted) {
                localStorage.setItem(
                    'contact',
                    clb.responseUnsafe.contact.phone_number
                );
                localStorage.setItem("shouldAsk", "false")
                f7.views.main.router.navigate('/about');
            }
        });
    }
    const fetchUser = async (telegramId) => {
        console.log('Telegram ID:', telegramId);
        try {
            const response = await fetch('https://ithub-community.uz/fetch_user.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telegramId })
            });

            const data = await response.json();
            if (data.status === 'registered') {
                f7.views.main.router.navigate('/redirect');
            }
        } catch (error) {
            console.error('Ошибка при проверке пользователя', error);
        }
    };
    return (
        <Page>

            <div  className="App">
                <div style={{textAlign: 'center',marginTop: 30, minHeight: 100}} >
                    <h1 style={{marginTop: 10, fontSize: 30}}> <span className={"ithub-title"}>ITHub</span>ga xush kelibsiz</h1>
                    <h1 style={{marginTop: 10, fontSize: 28}}> Добро пожаловать в <span className={"ithub-title"}>ITHub</span></h1>
                </div>
                <div style={{marginTop: 50}}>
                    <BlockHeader>Tilni tanlang / Выберите язык</BlockHeader>
                    <List strongIos outlineIos dividersIos mediaList  inset>

                        <ListItem
                            checked={language === "uz"}
                            radio
                            name="demo-media-radio"
                            value="uz"
                            title="O'zbekcha 🇺🇿"
                            text="UZ"
                            onChange={() => onChangeLang('uz')}
                        />
                        <ListItem
                            radio
                            checked={language === "ru"}
                            name="demo-media-radio"
                            value="ru"
                            title="Русский 🇷🇺"
                            text="RU"
                            onChange={() => onChangeLang('ru')}
                        />
                    </List>
                </div>
            </div>
        </Page>
    );
}

export default Start;
