import './App.css';
import {
    App, f7ready, View
} from "framework7-react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Start from "./Screens/Start";
import AboutMe from "./Screens/AboutMe";
import Role from "./Screens/Role";
import Redirect from "./Screens/Redirect";

function MyApp() {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const f7params = {
        name: 'My App',
        // specify routes for app
        routes: [
            {
                path: '/',
                component: Start,
            },
            {
                path: '/about',
                component: AboutMe,
            },
            {
                path: '/role',
                component: Role,
            },
            {
                path: '/redirect',
                component: Redirect,
            },
        ],
    };
  return (
      <App theme={"ios dark"} name="My App" {...f7params}>
          <View main url="/" />
      </App>
  );
}

export default MyApp;
