import '../App.css';
import {
    App, BlockHeader, BlockTitle, f7ready, f7, Input, List, ListInput, ListItem, Page,
} from "framework7-react";
import {useEffect, useState} from "react";
import logo from "../assets/gender.svg"
import map from "../assets/map.svg"
import {useTranslation} from "react-i18next";
import keyboard from "../assets/keyboard.svg"
import gradcap from "../assets/gradcap.svg"
import experience from "../assets/experience.svg"


function Role() {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const { i18n, t } = useTranslation();
    const specialities = t('digitalRoles', { returnObjects: true });
    const ITspecs = t('ITroles', { returnObjects: true });
    const experiences = t('experiences', {returnObjects: true});
    const levels = ["Senior", "Middle", "Junior", "Intern", "Student"];

    const [sphere, setSphere] = useState("");
    const [job, setJob] = useState("");
    const [userJob, setUserJob] = useState(null);
    const [level, setLevel] = useState("");

    const [jobIsEmpty, setJobIsEmpty] = useState(false);
    const [levelIsEmpty, setLevelIsEmpty] = useState(false)
    const [userJobIsEmpty, setUserJobIsEmpty] = useState(false);

    // const [userSpec, setUserSpec] = useState("");
    useEffect(() => {
        const handleBackButton = () => {
            f7.views.main.router.back()
        }

        const handleMainButton = () => {
            let canNavigate = true;
                if (job === "Other" || job === "Others") {
                    if (userJob === null || userJob.trim().length === 0){
                        setUserJobIsEmpty(true)
                        canNavigate = false
                    }
                }

                if (job === "") {
                    setJobIsEmpty(true)
                    canNavigate = false
                } else {
                    setJobIsEmpty(false)
                }

                if (level === "") {
                    setLevelIsEmpty(true)
                    canNavigate = false
                } else {
                    setLevelIsEmpty(false)
                }
                let finalJob = job;
                if (job === "Other" || job === "Others") {
                    finalJob = userJob;
                }
                localStorage.setItem('sphere', sphere);
                localStorage.setItem('job', finalJob);
                localStorage.setItem('level', level);

                if (canNavigate) {
                    f7.views.main.router.navigate('/redirect')
                }

        }
        window.Telegram.WebApp.MainButton.isVisible = true;
        window.Telegram.WebApp.BackButton.isVisible = true;
        window.Telegram.WebApp.MainButton.text = t("mainButton")
        window.Telegram.WebApp.onEvent("backButtonClicked", handleBackButton )
        window.Telegram.WebApp.onEvent("mainButtonClicked", handleMainButton)
        return () => {
            window.Telegram.WebApp.offEvent("backButtonClicked", handleBackButton);
            window.Telegram.WebApp.offEvent("mainButtonClicked", handleMainButton);
        };
    },[userJob, job, level])

    return (
        <Page>
            <BlockTitle style={{fontSize: 25, overflow: 'visible', wordWrap: 'break-word'}}>{t("customSpec")}</BlockTitle>
            <div style={{marginTop: 50}}>
                <BlockHeader>{t("specialityTitle")}</BlockHeader>
            <List strongIos dividersIos insetIos>
                <ListItem
                    smartSelectParams={{
                    openIn: 'popup',
                    inputIconPosition: 'end',
                    cssClass: "strongIos dividersIos insetIos",
                    closeOnSelect: true,
                }}
                           title={t("sphere")}
                           smartSelect
                >
                    <img
                        slot="media"
                        style={{ borderRadius: '4px' }}
                        src={keyboard}
                        width="20"
                    />
                    <select name={sphere}
                            value={sphere}
                            onChange={(e) =>  {setSphere(e.target.value); setJob(""); setLevel("");}}>
                        <option disabled value="">{t('choseSphere')}</option>
                        <option key="IT" value="IT">IT</option>
                        <option key="Digital" value="Digital">Digital</option>
                    </select>
                </ListItem>
            </List>
                {sphere === "Digital" ? (
                    <List strongIos dividersIos insetIos>
                    <ListItem
                        style={{marginTop: -20}}
                        smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                        title={t("speciality")}
                        smartSelect
                    >
                        <img
                            slot="media"
                            style={{ borderRadius: '4px' }}
                            src={gradcap}
                            width="20"
                        />
                        <select
                            name={job}
                            value={job}
                            onChange={(e) =>  setJob(e.target.value)}>
                        <option disabled value="">{t("choseSpeciality")}</option>
                        {specialities.map((spec, index) => (
                                <option key={index} value={spec}>{spec}</option>
                            )
                        )}
                        </select>
                    </ListItem>
                    </List>
                ) : (<List strongIos dividersIos insetIos>
                    <ListItem
                        style={{marginTop: -20}}
                        smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                        title={t("speciality")}
                        smartSelect
                    >
                        <img
                            slot="media"
                            style={{ borderRadius: '4px' }}
                            src={gradcap}
                            width="20"
                        />
                        <select
                            name={job}
                            value={job}
                            onChange={(e) =>  setJob(e.target.value)}>
                            <option disabled value="">{t("choseSpeciality")}</option>
                            {ITspecs.map((spec, index) => (
                                    <option key={index} value={spec}>{spec}</option>
                                )
                            )}
                        </select>
                    </ListItem>
                </List>)}
                {((job === 'Other') || (job === "Others")) && <List strongIos dividersIos insetIos>
                    <ListInput
                        style={{marginTop: -20}}
                        type="text"
                        placeholder={t("customSpec")}
                        value={userJob}
                        onChange={(e) => setUserJob(e.target.value)}
                        errorMessageForce={userJobIsEmpty}
                        errorMessage={t("specialityTitle")}
                    />
                </List>}
                {sphere === "Digital" ? (
                    <List strongIos dividersIos insetIos>
                        <ListItem
                            style={{marginTop: -20}}
                            smartSelectParams={{
                                openIn: 'popup',
                                inputIconPosition: 'end',
                                cssClass: "strongIos dividersIos insetIos",
                                closeOnSelect: true,
                            }}
                            title={t("experience")}
                            smartSelect
                        >
                            <img
                                slot="media"
                                style={{ borderRadius: '4px' }}
                                src={experience}
                                width="25"
                            />
                            <select
                                name={experience}
                                value={level}
                                onChange={(e) =>  setLevel(e.target.value)}>
                                <option disabled value="">{t("choseSpeciality")}</option>
                                {experiences.map((spec, index) => (
                                        <option key={index} value={spec}>{spec}</option>
                                    )
                                )}
                            </select>
                        </ListItem>
                    {jobIsEmpty && <p style={{fontSize: 13, color: "red"}}>{t("specialityTitle")}</p>}
                    {levelIsEmpty && <p style={{fontSize: 13, color: "red"}}>{t("enterLevel")}</p>}
                    </List>
                ) : (<List strongIos dividersIos insetIos>
                    <ListItem
                        style={{marginTop: -20}}
                        smartSelectParams={{
                            openIn: 'popup',
                            inputIconPosition: 'end',
                            cssClass: "strongIos dividersIos insetIos",
                            closeOnSelect: true,
                        }}
                        title={t("level")}
                        smartSelect
                    >
                        <img
                            slot="media"
                            style={{ borderRadius: '4px' }}
                            src={experience}
                            width="25"
                        />
                        <select
                            name={level}
                            value={level}
                            onChange={(e) => setLevel(e.target.value)}>
                            <option disabled value="">{t('choseLevel')}</option>
                            {levels.map((spec, index) => (
                                    <option key={index} value={spec}>{spec}</option>
                                )
                            )}
                        </select>
                    </ListItem>
                    {jobIsEmpty && <p style={{fontSize: 13, color: "red"}}>{t("specialityTitle")}</p>}
                    {levelIsEmpty && <p style={{fontSize: 13, color: "red"}}>{t("enterLevel")}</p>}
                </List>)}
            </div>
        </Page>
    );
}

export default Role;