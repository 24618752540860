import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {f7ready, Page} from "framework7-react";
import BoomStickC from "../Components/BoomStickC";
import { initUtils } from '@tma.js/sdk';

const Redirect = () => {
    f7ready(() => {
        // Framework7 is initialized, now you can safely use its APIs
    });
    const { i18n, t } = useTranslation();
    const utils = initUtils();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.async = false;

        script.onload = () => {
            const user = window.Telegram.WebApp.initDataUnsafe.user;
            if (user && user.id) {
                fetchUser(user.id);
            }
            window.Telegram.WebApp.MainButton.text = t('join');
            window.Telegram.WebApp.MainButton.isVisible = true;
            window.Telegram.WebApp.BackButton.hide();
        };

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [i18n, t]);
    const fetchUser = async (telegramId) => {
        console.log('Telegram ID:', telegramId);
        try {
            const response = await fetch('https://ithub-community.uz/fetch_user.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telegramId })
            });

            const data = await response.json();
            if (data.status === 'registered') {
                window.Telegram.WebApp.onEvent('mainButtonClicked', () => {
                    window.Telegram.WebApp.MainButton.disable();
                    window.Telegram.WebApp.MainButton.text = t('redirect_to_group');

                    utils.openTelegramLink('https://t.me/+hZbk2q2EV1w1ZDJi');
                    window.Telegram.WebApp.close();
                });
            } else {
                window.Telegram.WebApp.onEvent('mainButtonClicked', async () => {
                    const data = {
                        telegramId: localStorage.getItem('telegramId'),
                        language: localStorage.getItem("language"),
                        contact: localStorage.getItem("contact"),
                        username: localStorage.getItem('username'),
                        name: localStorage.getItem('name'),
                        lastName: localStorage.getItem('lastName'),
                        birthday: localStorage.getItem('birthday'),
                        gender: localStorage.getItem('gender'),
                        region: localStorage.getItem('region'),
                        sphere: localStorage.getItem('sphere'),
                        job: localStorage.getItem('job'),
                        level: localStorage.getItem('level'),
                    };
                    window.Telegram.WebApp.MainButton.disable();
                    window.Telegram.WebApp.MainButton.text = t('redirect_to_group');

                    try {
                        const response = await fetch('https://ithub-community.uz/save.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json; charset=utf-8'
                            },
                            body: JSON.stringify(data)
                        });

                        if (!response.ok) {
                            throw new Error('Network response was not ok ' + response.statusText);
                        }
                        utils.openTelegramLink('https://t.me/+hZbk2q2EV1w1ZDJi');
                        window.Telegram.WebApp.close();
                    } catch (error) {
                        console.error('Error saving and sending data:', error);
                    }
                });
            }
        } catch (error) {
            console.error('Ошибка при проверке пользователя', error);
        }
    };
    return (
        <Page >
            <div style={{marginTop: 20}}>
                <BoomStickC/>
            </div>
            <div style={{textAlign: 'center'}}>
                <h1>{t("done")}</h1>
                <p style={{fontSize: 20, fontWeight: 'bold', paddingLeft: 25, paddingRight: 25}}>{t("doneText")}</p>
            </div>
        </Page>
    );
};

export default Redirect;